import axios from "axios";
import React from "react";
import ParcoursContext from "./ParcoursContext";

const Context = (props) => {
  const [souscripteur, setSouscripteur] = React.useState([
    {
      nom: "",
      prenom: "",
      dn: "",
      region: "fr",
      adresse: "",
      cp: "",
      ville: "",
      civility: "",
      mail: "",
      phone: "",
      country_prefix: "+33",
      relationship: "primary subscriber",
      relationship_rank: 1,
      primary_subscriber: true,
      jourprelevement: 5,
      code_insee: "",
    },
  ]);
  const [proposition, setProposition] = React.useState([
    "Assistance",
    "Protection Juridique",
  ]);
  const [data, setData] = React.useState({
    "Souhaitez-vous être garantie par une indemnité journalière en cas d’hospitalisation suite à maladie ou accident ?":
      "oui",
    "Une indemnité de 40€ en cas d’hospitalisation qui est doublée en cas d’accident vous parait-elle suffisante ?":
      "oui",
    "Vous avez déclaré ne pas pratiquer de discipline sportive exclue aux conditions générales du contrat":
      "oui",
    "Faites-vous l’objet d’une mesure de Curatelle ou de Tutelle ?": "non",
    "Vous déclarez gérer vous-même vos papiers sans une aide extérieure ?": "oui",
    "Avez-vous déjà ce type d’indemnité ?": "non",
    "Vous ou l'un des assurés pratique-t-il un sport ?": "non",
    "Vous ou l’un des assurés au contrat a-t’il une hospitalisation programmée ?":
      "non",
    "quel monatnt?": "",
    "quel assureur?": "",
    "êtes vous une personne politiquement exposée ?": "non",
    "Souhaitez-vous que nous nous chargions d'expédier, en votre nom et pour votre compte, la lettre de résiliation ?":
      "non",
    "Vous ou l'un des assurés pratique-t-il l’un des sports suivants, qui ne sont pas garantis :":
      [],
  });
  const [modal, setModal] = React.useState(false);
  const [customerInformations, setCustomerInformations] = React.useState([]);
  const [image, setImage] = React.useState("");
  const [destination, setDestination] = React.useState("");
  const [objetEmail, setObjetEmail] = React.useState("");
  const [entreprise, setEntreprise] = React.useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState("");
  const [shadowColor, setShadowColor] = React.useState("");
  const [productColor, setProductColor] = React.useState("");
  const [confirmButtonColor, setConfirmButtonColor] = React.useState("");
  const [retourButtonColor, setRetourButtonColor] = React.useState("");
  const [addChildColor, setAddChildColor] = React.useState("");
  const [addConjointColor, setAddConjointColor] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [organisme, setOrganisme] = React.useState(null);

  const [email, setEmail] = React.useState(
    `<p style='text-align:center;'><strong>Bonjour,</strong></p>  <p>Suite à notre entretien, je vous transmets notre solution en matière de complémentaire santé.Pour visualiser notre proposition, veuillez cliquer sur le lien suivant :</p>`
  );
  React.useEffect(() => {
    const key = new URLSearchParams(window.location.search).get(
      "partnership_id"
    );
    const apiKey = key ? key : process.env.REACT_APP_API_KEY;
    localStorage.setItem("api_key", apiKey);
    let config = {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };
    axios
      .post(`${process.env.REACT_APP_GEOPROD_APIURL}login`, {}, config)
      .then((res) => {
        localStorage.setItem("token", res.data.Acces_Token);
        axios
          .get(
            `${process.env.REACT_APP_GEOPROD_APIURL}organismes/get_by_id/${res.data?.user?.entreprise_id}`,
            {
              headers: {
                idSession: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            setEntreprise(res.data);
            setLogo(res.data?.logo);
            setAddChildColor(
              res.data?.brand?.parcours_souscription?.couleurs[7] || "#d2efeb"
            );
            setAddConjointColor(
              res.data?.brand?.parcours_souscription?.couleurs[6] || "c4cee6"
            );
            setRetourButtonColor(
              res.data?.brand?.parcours_souscription?.couleurs[0] || "#e00000"
            );
            setConfirmButtonColor(
              res.data?.brand?.parcours_souscription?.couleurs[5] || "#254484"
            );
            setBackgroundColor(
              res.data?.brand?.parcours_souscription?.couleurs[2] || "#13274f"
            );
            setShadowColor(
              res.data?.brand?.parcours_souscription?.couleurs[0] || "#e00000"
            );
            setProductColor(
              res.data?.brand?.parcours_souscription?.couleurs[0] || "#e00000"
            );
            setImage(res.data?.logo);
          })
          .catch((err) => console.log(err));
          axios
          .get(`${process.env.REACT_APP_GEOPROD_APIURL}get_user_with_id`, {
            headers: {
              idSession: localStorage.getItem("token"),
            },
          })
          .then((res) => {
            setOrganisme(res.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <ParcoursContext.Provider
      value={{
        souscripteur,
        setSouscripteur,
        customerInformations,
        setCustomerInformations,
        modal,
        setModal,
        image,
        setImage,
        destination,
        setDestination,
        objetEmail,
        setObjetEmail,
        email,
        setEmail,
        proposition,
        setProposition,
        entreprise,
        setEntreprise,
        productColor,
        backgroundColor,
        shadowColor,
        confirmButtonColor,
        retourButtonColor,
        addConjointColor,
        addChildColor,
        logo,
        organisme,
        setOrganisme,
        data, setData
      }}
    >
      {props.children}
    </ParcoursContext.Provider>
  );
};

export default Context;
